import request from '@/utils/request'

export function listZone() {
  return request({
    url: '/sys/zone/list',
    method: 'get'
  })
}

export function addZone(data) {
    return request({
      url: '/sys/zone/add',
      method: 'post',
      data
    })
  }
  export function updataZone(data) {
    return request({
      url: '/sys/zone/update',
      method: 'post',
      data
    })
  }
  export function delZone(params) {
    return request({
      url: `/sys/zone/delete`,
      method: 'get',
      params
    })
  }
  export function getZone() {
    return request({
      url: `/sys/zone/zones`,
      method: 'get',
    })
  }

  export function getListSite(data) { //区域新增的时候 查询站点列表和区域列表
    return request({
      url: `/sys/zone/listSite`,
      method: 'get',
      params:data
    })
  }
  