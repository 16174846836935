<template>
    <el-dialog
    v-model="dialogVisible"
    :title="type?'编辑信息':'添加信息'"
    width="40%"
    >
    <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="120px"
    size="large">
        <el-form-item label="类型" prop="type">
            <el-select :disabled="true" style="width:100%" @change="typeChange" v-model="ruleForm.type" placeholder="请选择区域类型">
                <el-option label="区域" :value="2" />
                <el-option label="站点" :value="3" />
            </el-select>
        </el-form-item>
        <el-form-item label="区域名称" prop="name">
            <el-select filterable style="width:100%" v-model="ruleForm.name" :disabled="type==1"  placeholder="请选择区域">
                <el-option v-for="(item, index) in areaList" :key="index" :label="item.region" :value="item.region" />
            </el-select>
        </el-form-item>
        <el-form-item label="经度" prop="lon">
            <el-input v-model="ruleForm.lon" />
        </el-form-item>
        <el-form-item label="纬度" prop="lat">
            <el-input v-model="ruleForm.lat" />
        </el-form-item>
        
        <el-form-item label="父级" prop="fatherId" v-if="ruleForm.type==3">
            <el-tree-select 
            style="width:100%"
            disabled 
            v-model="ruleForm.fatherId" 
            :data="zoneList" 
            node-key="code"
            default-expand-all
            :props="{label:'name',children:'children'}"/>
        </el-form-item>
        <el-form-item label="所在城市"  prop="city">
            <el-input v-model="ruleForm.city" />
        </el-form-item>
        <el-form-item label="描述概览" prop="overview">
            <el-input type="textarea" autosize v-model="ruleForm.overview" />
        </el-form-item>
        <el-form-item label="天气类型" v-if="ruleForm.type == 3"  prop="weatherType">
            <el-radio-group v-model="ruleForm.weatherType">
                <el-radio :label="1">天气网</el-radio>
                <el-radio :label="2">气象站</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="天气获取地址" v-if="ruleForm.type == 3" prop="weatherUrl">
            <el-input v-model="ruleForm.weatherUrl" />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="formSubmit('ruleFormRef')">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { addZone, updataZone, getZone, getListSite } from '@/api/area.js'
export default {
    emits:['OK'],
    data(){
        return{
            dialogVisible:false,
            type:0,
            ruleForm:{
                name:'',
                city:'',
                overview:'',
                lon:'',
                lat:'',
                type:'',
                fatherId:'',
                weatherType:'',
                weatherUrl:''
            },
            rules:{
                city:{ required: true, message: '所在城市不能为空', trigger: 'blur' },
                name:{ required: true, message: '区域名称不能为空', trigger: 'blur' },
                lon:{ required: true, message: '经度不能为空', trigger: 'blur' },
                lat:{ required: true, message: '纬度不能为空', trigger: 'blur' },
                type:{ required: true, message: '请选择类型', trigger: 'blur' },
                fatherId:{ required: true, message: '请选择父级', trigger: 'blur' },
                weather_type:{ required: true, message: '请选择天气类型', trigger: 'blur' },
                weather_url:{ required: true, message: '请输入天气获取地址', trigger: 'blur'}
            },
            zoneList:[],
            areaList:[],
            
        }
    },
    mounted(){
    },
    methods:{
        GetZone(){
            getZone().then(r=>{
                this.zoneList = this.operTree(r.data.children) 
            })
        },
        operTree(aList){
            return aList.filter(item => {
                return item.type != 3
            }).map(item => {
                item = Object.assign({}, item)
                if (item.children) {
                    item.children = this.operTree(item.children)
                }
                return item
            })
        },
        open(type,data){
            this.type = type
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.ruleFormRef.resetFields() 
                if(type == 1){
                    this.ruleForm = {
                        id:data.code,
                        name:data.name,
                        city:data.city,
                        overview:data.overview,
                        lon:data.lon,
                        lat:data.lat,
                        type:data.type,
                        fatherId:data.fatherId,
                        weatherType:data.weather_type,
                        weatherUrl:data.weather_url
                    }
                }else{
                    this.ruleForm.fatherId = data.code
                    if(data.type==1){
                        this.ruleForm.type = 2
                    }else if(data.type == 2){
                        this.ruleForm.type = 3
                    }
                }
                this.GetListSite()
            })
        },
        typeChange(data){
            this.ruleForm.type = data
            this.ruleForm.name = ''
            this.GetListSite()
        },
        GetListSite(){
            let query = {
                type:this.ruleForm.type,
                area:this.ruleForm.fatherId
            }
            getListSite(query).then(r=>{
                if(this.ruleForm.type == 2){
                    this.areaList = r.data
                }else if(this.ruleForm.type == 3){
                    this.areaList = r.data.map(i=>{
                        let obj = {
                            region:i.name,
                            id:i.code
                        }
                        return obj
                    })
                }   
            })
        },
        formSubmit(formName){   
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    if(this.type == 0){
                        if(this.ruleForm.type==2){
                            delete this.ruleForm.fatherId;
                        }
                        this.AddData(this.ruleForm)
                    }else{
                        if(this.ruleForm.type==2){
                            delete this.ruleForm.fatherId;
                        }
                        this.EditData(this.ruleForm)
                    }
                } else {
                    return false;
                }
            });
        },
        AddData(data){
            let query = JSON.parse(JSON.stringify(data))
            query.id = this.areaList.find(i=>{
                return i.region == query.name
            }).id
            addZone(query).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.dialogVisible = false
                    this.$emit('OK',0)
                }else{
                    this.$message({
                        type: 'warning',
                        message: '新增失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
        EditData(data){
            let query = JSON.parse(JSON.stringify(data))
            updataZone(query).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '编辑成功!'
                    });
                    this.dialogVisible = false
                    this.$emit('OK',1)
                }else{
                    this.$message({
                        type: 'warning',
                        message: '编辑失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__header{
    margin-right: 0px;
    background: #774A23;   
}
/deep/.el-dialog__title{
    color: white;
}
/deep/.el-dialog__headerbtn .el-dialog__close{
    color: white;
}
</style>