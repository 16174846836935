<template>
    <div class="pageBox">
        <!-- <div class="topBox">
            <el-button type="primary" size="large">
                <svg-icon icon-class="plus"/>&nbsp;&nbsp;添加
            </el-button>
        </div> -->
        
        <div>
            <el-table 
            size="large" 
            row-key="code"
            default-expand-all
            :data="tableData" 
            style="width: 100%">
                <el-table-column prop="name" width="300" label="区域名称"/>
                <el-table-column prop="code" label="区域编码"  />
                <el-table-column prop="city" label="所在城市" />
                <!-- <el-table-column label="描述概览">
                    <template #default="scope">
                        <div v-if="scope.row.overview" v-html="scope.row.overview.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')"></div>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column prop="lon" label="经度" align="center" />
                <el-table-column prop="lat" label="纬度" align="center" /> -->
                <el-table-column prop="type" label="类型" align="center">
                    <template #default="scope">
                        <el-tag type="danger" v-if="scope.row.type == 1">国家</el-tag>
                        <el-tag type="success" v-if="scope.row.type == 2">区域</el-tag>
                        <el-tag v-if="scope.row.type == 3">站点</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button v-if="scope.row.type == 1" type="success" size="small"  plain  @click="oper(0,scope.row)">新增区域</el-button>
                        <el-button v-if="scope.row.type == 2" type="success" size="small"  plain  @click="oper(0,scope.row)">新增站点</el-button>
                        <el-button v-if="scope.row.type != 1" type="warning" size="small" @click="oper(1,scope.row)" plain>编辑</el-button>
                        <el-button v-if="scope.row.type != 1" type="danger" size="small" @click="del(scope.row.code)" plain>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <operForm ref="operForm" @OK="getlistZone"/>
    </div>
</template>
<script>
import operForm from './Modules/oper.vue'
import { listZone, delZone } from '@/api/area.js'
export default {
    components:{
        operForm,
    },
    data(){
        return{
            tableData:[]
        }
    },
    mounted(){
        this.getlistZone()
    },
    methods:{
        getlistZone(){
            listZone().then(r=>{
                this.tableData = r.data.lists
                this.$refs.operForm.GetZone()
            })
        },
        oper(type,data){
            this.$refs.operForm.open(type,data)
        },
        del(id){
            this.$confirm(
                '此操作将永久删除该信息，是否继续?',
                'Warning',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
                let para = { id }
                delZone(para).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getlistZone()
                })
            })
            .catch(() => {
                
            })
        },
        
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
}
.topBox{
    width: 100%;
    margin-bottom: 20px;
}

.el-pagination{
    margin-top: 20px;
    justify-content: center;
}
</style>